<template>
  <div>
    <div class="bg-white border bd-e4 px-3">
      <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
      <div class="bg-white pt-3">
        <PersonProfile
          :person="person_disease && person_disease.person"
          :disease="person_disease"
          :monitor_stats="monitor_stats"
        ></PersonProfile>
      </div>
      <TypeBar :showing="show" :person_id="person_id" :disease="person_disease" />
      <TestCreateOptions @onShowModalUpload="onShowModalUpload" @uploadFilePacs="uploadFilePacs"></TestCreateOptions>
    </div>
    <input
      id="file-upload"
      class="d-none"
      name="file-upload"
      type="file"
      accept=".pdf, .docx, .doc, .xlsx, .xls, .jpeg, .jpg, .gif, .png, .mp4, .ogx, .oga, .ogv, .ogg, .webm, .zip, .rar"
      @change="onSelectFile($event)"
    />
    <ModalDocument
      @onShowPickFile="onShowPickFile"
      @changeContent="changeContent"
      :file="file"
      @onSubmit="createTestResult"
      :loading="loading"
    />
    <ModalUploadPacs @onUploadPac="onUploadPac" />
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import TestCreateOptions from '../../components/Diseases/TestCreateOptions'
import ModalDocument from '../../components/Diseases/ModalDocument.vue'
import ModalUploadPacs from '../../components/Files/ModalUploadPacs.vue'
import uploadS3File from '../../utils/uploadS3File'

export default {
  name: 'TestCreateOptionsPage',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    TestCreateOptions,
    ModalDocument,
    ModalUploadPacs
  },
  data () {
    return {
      type: 1,
      show: 'test',
      title: 'Thông tin sức khoẻ',
      person_id: null,
      person: null,
      danger_symptoms: [],
      disease: null,
      file: null,
      content: '',
      person_disease: null,
      loading: false,
      uploadS3File,
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesDetail()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/test-info`
      })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then(res => {
            if (res && res.data) {
              self.person_disease = res.data
              self.person_id = self.person_disease.person_id
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onSelectFile (event) {
      this.file = event.target.files[0]
    },
    async onSubmit () {
      let self = this
      self.loading = true
      try {
        await self.onUploadDocument().finally(() => {
          self.loading = false
          self.onShowModalUpload(false)
          self.onClose()
        })
      } catch (e) {
        self.loading = false
        console.log(e)
      } finally {
      }
    },
    async createTestResult (data) {
      let self = this
      let params = {
        test_result_name: data.name,
        person_id: self.person_id,
        person_diseases_id: self.person_disease && self.person_disease.id,
        test_result_desc: data.content,
        type: 1
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .createPersonDiseaseTestResult(params)
        .then(resp => {
          if (resp.data) {
            return self.onUploadDocument(resp.data)
          }
        })
    },
    async onUploadDocument (data) {
      let self = this
      self.loading = true
      let params = {
        file: self.file,
        content: data.test_result_name,
        type: 3,
        person_diseases_id: self.person_disease && self.person_disease.id,
        person_test_result_id: data.id
      }
      var formData = new FormData()
      for (var key in params) {
        if (params[key]) {
          formData.append(key, params[key])
        }
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .uploadPersonDiseaseDocument(self.person_id, formData)
        .then(res => {
          self.file = null
          self.content = ''
          self.loading = false
          self.onShowModalUpload(false)
          self.onClose()
          return res
        })
    },
    onShowModalUpload (show) {
      window.$('#modal__document').modal(show ? 'show' : 'hide')
    },
    onShowPickFile () {
      window.$('input[id="file-upload"]').trigger('click')
    },
    changeContent (txt) {
      this.content = txt
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then(resp => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    uploadFilePacs (show) {
      console.log('uploadFilePacs')
      window.$('#modalUploadPac').modal(show ? 'show' : 'hide')
    },
    async onUploadPac (file) {
      if (!file) return
      const resultUrl = await uploadS3File.uploadHandler(
        this.$route.params.id,
        0,
        [file],
        3
      )
      setTimeout(() => {
        this.uploadFilePacs()
        // this.$router.push({ query: null })
      }, 300)
      const formatUrl = resultUrl?.map((item, index) => {
        return {
          ...item,
          name: file?.[index]?.name
        }
      })

      await this.handleSubmitFilleUrl(formatUrl)
      this.$router.push({ name: 'TestInformationManagement' })
    },
    async handleSubmitFilleUrl (urlList) {
      const self = this
      if (!urlList?.length) return

      await Promise.all(
        urlList?.map(async (urlData, index) => {
          if (!urlData.url) return

          const params = {
            person_disease_id: +this.$route.params?.id,
            pac_dicom_id: urlList[0]?.pac_id || 0
          }

          await this.$rf
            .getRequest('DoctorRequest')
            .linkPacsToHSSK(params)
            .then(r => {
              return r.data
            })
            .catch(error => {
              console.log('error', error)
            })
            .finally(() => {
              self.isLoading = false
            })
        })
      )
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>
