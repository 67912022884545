<template>
    <div class="bg-white border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="bg-white pt-3">
            <PersonProfile v-if="person" :person="person" :disease="disease" @showModalAppt="showModalAppt" :monitor_stats="monitor_stats"></PersonProfile>
        </div>
        <TypeBar :showing="show" :person_id="person_id" @onShowModalUpload="onShowModalUpload" :disease="disease"/>
        <div>
            <div class="table-responsive">
                <table class="table table-vcenter table-mobile-md card-table table-scroll">
                    <thead class="d-none d-md-table bg--primary-corl">
                        <tr class="">
                            <th class="robo-20-500 pl-sm-2" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">
                             {{ $t("obj_health_records.lbl_attachment") }}
                            </span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">
                             {{ $t("obj_health_records.lbl_day") }}
                            </span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">
                             {{ $t("obj_health_records.lbl_uploaded_by") }}
                            </span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;"><span class="txt-pri-corl">
                             {{ $t("obj_health_records.lbl_attachment_type") }}
                            </span></th>
                            <th class="robo-20-500 text-sm-center" style="background-color: #E7EAF5 !important;">
                                <span class="txt-pri-corl">
                                  {{ $t("obj_health_records.lbl_action") }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="documents && documents.data">
                        <div v-if="documents && documents.count > 0" v-for="(doc, index) in documents.data" class="border-0">
                            <tr class="border-0">
                                <td data-label="Tên thuốc" class="table-td-name-avatar robo-20-400 pl-sm-2">
                                    <span class="txt-pri-corl">{{doc && doc.content}}</span>
                                </td>
                                <td data-label="ĐVT" style="text-transform: capitalize;" class="robo-20-400 text-sm-center">
                                    <span class="txt-pri-corl">{{formatDMY(doc.created_at)}}</span>
                                </td>
                                <td data-label="SL" class="table-td-action text-sm-center">
                                    <p class="mb-0"><span class="txt-pri-corl">{{doc.doctor ? doc.doctor.name : doc.person ? doc.person.name : ''}}</span></p>
                                </td>
                                <td data-label="SL" class="table-td-action text-sm-center">
                                    <p class="mb-0"><span class="txt-pri-corl">{{getTypeNameDoc(doc.type)}}</span></p>
                                </td>
                                <td data-label="Liều lượng" class="table-td-action text-sm-center">
                                    <a download target="_blank" :href="getLinkDownload(doc.link)" :download="doc.name"><img src="../../../public/assets/images/icon/dowload.svg" height="24" width="24" alt="" class="cursor-pointer"></i></a>
                                </td>
                            </tr>
                        </div>
                        <tr v-if="documents && documents.count === 0">
                            <td colspan="5">
                                <div class="col-sm-12 robo-14-400 text-center">
                              {{ $t("obj_health_records.no_data") }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination v-show="documents && documents.count" :items="documents" :current_page="current_page" @onRefresh="getPersonsDocument"></Pagination>
            </div>
        </div>
        <div>
            <input id="file-upload" class="d-none" name='file-upload' type="file" @change="onSelectFile($event)">
            <ModalDocument @onShowPickFile="onShowPickFile" @changeContent="changeContent" :file="file" @onSubmit="onUploadDocument" :loading="loading"/>
            <ModalAppointment v-if="!!person && !!disease" @onRefresh="showModalAppt(false)" :person_id="person ? person.id : null" :person_diseases_id="disease.id" :root_screen="'Diseases Document'" :screen="'Diseases Document'" :request_id="disease.request ? disease.request.id : null"></ModalAppointment>
        </div>
    </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination'
import ModalDocument from '../../components/Diseases/ModalDocument.vue'
import appUtils from '../../utils/appUtils'
import ModalAppointment from '../../components/Appointment/ModalAppointment'
export default {
  name: 'DocumentManagement',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    Pagination,
    ModalDocument,
    ModalAppointment
  },
  data () {
    return {
      type_date: null,
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'document',
      title: this.$t('obj_health_records.lbl_original_document'),
      person_id: null,
      person: null,
      file: null,
      content: '',
      documents: [],
      current_page: 1,
      disease: null,
      loading: false,
      monitor_stats: null
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id
      })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
            let person = res.data.person || null
            self.person_id = person ? person.id : null
            self.person = person || null
            setTimeout(() => {
              self.getPersonsDocument(self.person_id)
            }, 100)
          })
          .then(() => {})
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonsDocument () {
      let self = this
      let params = {
        person_diseases_id: self.disease && self.disease.id,
        sort: 'created_at',
        sort_by: 'desc'
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonDiseaseDocument(self.person_id, params)
        .then((res) => {
          self.documents = res.data
        })
    },
    onSelectFile (event) {
      this.file = event.target.files[0]
      console.log(this.file)
      // let r = confirm('Bạn có chắc chắn muốn tải lên file ' + this.file.name)
      // if (r) {

      // } else {
      //   alert('Bạn đã huỷ tải lên!')
      //   this.file = null
      // }
    },
    async onUploadDocument () {
      let self = this
      self.loading = true
      let params = {
        file: self.file,
        content: self.content,
        type: 1,
        person_diseases_id: self.disease && self.disease.id
      }
      var formData = new FormData()
      for (var key in params) {
        if (params[key]) {
          formData.append(key, params[key])
        }
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .uploadPersonDiseaseDocument(self.person_id, formData)
        .then((res) => {
          self.file = null
          self.content = ''
          self.onShowModalUpload(false)
          self.getPersonsDocument()
        })
        .finally(() => {
          self.loading = false
        })
    },
    onShowModalUpload (show) {
      window.$('#modal__document').modal(show ? 'show' : 'hide')
    },
    onShowPickFile () {
      window.$('input[id="file-upload"]').trigger('click')
    },
    changeContent (txt) {
      this.content = txt
    },
    formatDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    showModalAppt (is_show) {
      window.$('#modal__appt').modal(is_show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    getTypeNameDoc (type) {
      return type === 1
        ? this.$t('obj_health_records.lbl_health_record_exam')
        : type === 2
          ? this.$t('obj_health_records.lbl_medicine')
          : type === 3
            ? this.$t('obj_health_records.lbl_test')
            : ''
    }
  }
}
</script>
<style lang="css" scoped>
</style>
