<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        :person="person_disease && person_disease.person"
        :disease="person_disease"
        @showModalAppt="showModalAppt"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="person_disease" />
    <medicineInfo></medicineInfo>
    <ModalAppointment
      @onRefresh="onRefresh()"
      :person_id="person_disease && person_disease.person_id ? person_disease.person_id : null"
      :request_id="person_disease && person_disease.request_id ? person_disease.request_id : null"
      :person_diseases_id="person_disease && person_disease.person_id"
      :root_screen="'Diseases Case'"
      :screen="'Diseases Case'"
    ></ModalAppointment>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import MedicineInfo from '../../components/Diseases/MedicineInfo'
import ModalAppointment from '../../components/Appointment/ModalAppointment'
export default {
  name: 'MedicineAdd',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    MedicineInfo,
    ModalAppointment
  },
  data () {
    return {
      type: 1,
      show: 'medicines-add',
      title: 'Thông tin thuốc',
      person_id: null,
      person: null,
      danger_symptoms: [],
      disease: null,
      person_disease: null,
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesDetail()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id + '/medicine-table'
      })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then(res => {
            if (res && res.data) {
              self.person_disease = res.data
              self.person_id = self.person_disease.person_id
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    showModalAppt (is_show) {
      window.$('#modal__appt').modal(is_show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then(resp => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>
