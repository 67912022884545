<template>
    <div class="container-window mt-3 mr-3 border bd-e4 p-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="table-responsive">
            <table class="table table-vcenter table-mobile-md card-table table-scroll">
                <thead>
                    <tr>
                        <th><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Thời gian</span></th>
                        <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Lý do khám</span></th>
                        <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Diễn biến</span></th>
                        <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Tiền sử</span></th>
                        <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Chẩn đoán</span></th>
                        <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Chỉ định</span></th>
                        <!-- <th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #3153B1;">Chi chú</span></th> -->
                    </tr>
                </thead>
                <tbody v-if="ptvs && ptvs.count">
                    <tr class="mt-2 mb-2 bg-blue-gray" v-for="(ptv, index) in ptvs.data" :key="index">
                        <td data-label="Thời gian">
                          <p class="robo-16-400 txt-black ml-sm-2 mb-1">{{formatDateHMDMY(ptv.created_at)}}</p>
                          <p class="robo-16-400 txt-pri ml-sm-2 mb-1" v-if="ptv.updated_by">{{ptv.updated_by.name}}</p>
                        </td>
                        <td data-label="Lý do khám" class="text-sm-center"><span class="robo-16-400 txt-black">{{ptv.reason}}</span></td>
                        <td data-label="Diễn biến" class="text-sm-center">
                          <span class="robo-16-400 txt-black">{{ptv.progression}}</span>
                        </td>
                        <td data-label="Tiền sử" class="robo-16-500 text-sm-center">
                          <span class="robo-16-400 txt-black">{{ptv.prehistoric}}</span>
                          
                        </td>
                        <td data-label="Chẩn đoán" class="text-sm-center">
                          <span class="robo-16-400 txt-black">{{ptv.diagnose}}</span>
                            
                        </td>
                        <td data-label="Chỉ định" class="text-sm-center">
                          <span class="robo-16-400 txt-black">{{ptv.indication}}</span>
                            
                        </td>
                        <!-- <td data-label="Chi chú" class="text-sm-center">
                          <span class="robo-16-400 txt-black">{{ptv.notes}}</span>
                           
                        </td> -->
                    </tr>
                </tbody>
                <tbody v-if="!ptvs || !ptvs.count">
                    <tr>
                        <td colspan="5">
                            <div class="text-center pt-3 robo-16-500 txt-pri">
                                Chưa có lịch sử
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="ptvs && ptvs.count" :items="ptvs" :current_page="current_page" @onRefresh="getConsultationHistories"></Pagination>
        </div>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
export default {

  name: 'LichSuPhieuTuVan',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      title: 'Lịch sử cập nhật phiếu tư vấn',
      ptvs: null,
      person_diseases_id: '',
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.getConsultationHistories()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/consultation/${this.$route?.params?.id}` })
    },
    async getConsultationHistories () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 15,
          page: self.current_page,
          sort: 'created_at',
          sort_by: 'desc'
        }
        await self.$rf.getRequest('DoctorRequest').getConsultationHistories(self.$route?.params?.id, params).then(res => {
          if (res && res.data) {
            self.ptvs = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateHMDMY (date) {
      return window.moment(date).format('HH:mm DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.file-name {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.file-name p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-scroll tbody {
  height: unset;
}
</style>