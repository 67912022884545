<template>
  <ListDiseases></ListDiseases>
</template>
<script>
import ListDiseases from '../../components/Diseases/ListDiseases'
export default {
  components: { ListDiseases },
  name: 'PersonDiseasesManagement',
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>