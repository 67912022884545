<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        :person="person_disease && person_disease.person"
        :disease="person_disease"
        @showModalAppt="showModalAppt"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="person_disease" />
    <button
      class="btn bg-pri bd-pri text-white btn-add-new mt-2 flex gap-2 ml-auto rounded-sm"
      @click="addNew(true)"
      v-if="advices && advices.data && advices.data.length"
    >
      <img
        src="../../../../public/assets/images/icon/plus-circle-solid-white.svg"
        width="28"
        alt
      />
      <span>
        {{ $t("obj_health_records.lbl_create_new") }}
      </span>
    </button>
    <TodoAdd
      v-if="iss_add_new && person_disease"
      :person_disease="person_disease"
      @onCancelAddNew="addNew(false)"
      @refresh="getAdvices"
    />
    <TodoListTable
      v-if="!iss_add_new && person_disease"
      :page="page"
      :person_disease="person_disease"
      :advices="advices"
      @refresh="getAdvices"
    />
    <ListEmpty
      v-if="!iss_add_new && (!advices || !advices.data || !advices.data.length)"
      :title="$t('obj_health_records.lbl_there_are_no_instructions_yet')"
      @onAddNew="addNew(true)"
    />
  </div>
</template>
<script>
import PersonProfile from '../../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../../components/Diseases/TypeBar.vue'
import TodoListTable from '../../../components/Diseases/Todo/TodoListTable.vue'
import TodoAdd from '../../../components/Diseases/Todo/TodoAdd.vue'
import ListEmpty from '../../../components/Empty/ListEmpty.vue'
import i18n from '../../../lang'
export default {
  name: 'TodoList',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    TodoListTable,
    TodoAdd,
    ListEmpty
  },
  data () {
    return {
      type: 1,
      show: 'todo',
      title: i18n.t('obj_health_records.lbl_health_record'),
      person_id: null,
      person: null,
      disease: null,
      person_disease: {},
      monitor_stats: null,
      iss_add_new: false,
      page: 1,
      total: 0,
      advices: []
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesDetail()
    self.getStats()
  },
  watch: {
    '$route.query.page': {
      handler: function (page) {
        this.page = page || 1
        this.getAdvices()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id
      })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.person_disease = res.data || {}
              self.person_id = self.person_disease.person_id
              self.getAdvices()
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    showModalAppt (is_show) {
      window.$('#modal__appt').modal(is_show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    async getAdvices () {
      let self = this
      if (!self.$route?.params?.id) return
      let params = {
        limit: 15,
        page: self.page
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getAdvices(self.$route?.params?.id, params)
        .then((resp) => {
          self.advices = resp?.data
          self.total = resp?.data?.total
        })
    },
    addNew (stt) {
      this.iss_add_new = stt
    }
  }
}
</script>
<style lang="css" scoped>
</style>