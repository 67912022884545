<template>
  <div class="container-window mt-3 mr-3 border bd-e4 p-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <TestsTable
      :documents="documents"
      :list_tests="list_tests"
      @getTestsByDiseases="getTestsByDiseases"
    />
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
import TestsTable from '../../components/TestsGroup/TestsTable.vue'
export default {
  name: 'TestDetail',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, TestsTable },
  data () {
    return {
      title: 'Xét nghiệm',
      list_tests: null,
      // list_tests_converted: [],
      person_diseases_id: '',
      current_page: 1,
      person_test_result: null,
      documents: [],
      test_id: null
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.test_id = self.$route.params.test_id
    self.getTestsByDiseases()
    self.getTestResultDetail()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/test-info`
      })
    },
    async getTestResultDetail () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getTestResultDetail(self.$route.params.test_id)
        .then(resp => {
          self.person_test_result = resp.data
          self.title = resp.data.test_result_name
          self.documents = resp.data.document
        })
    },
    async getTestsByDiseases () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 100,
          page: self.current_page,
          person_test_result_id: self.test_id || null
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getTestsByDiseases(self.$route?.params?.id, params)
          .then(res => {
            if (res && res.data) {
              self.list_tests = res.data
              self.list_tests.data = self.list_tests?.data?.map(elm => {
                return {
                  ...elm,
                  test_value: elm.test_value_text ? elm.test_value_text : '',
                  upper_threshold: elm.upper_threshold_text
                    ? elm.upper_threshold_text
                    : elm.upper_threshold === '0.00'
                      ? ''
                      : elm.upper_threshold,
                  lower_threshold: elm.lower_threshold_text
                    ? elm.lower_threshold_text
                    : elm.lower_threshold === '0.00'
                      ? ''
                      : elm.lower_threshold
                }
              })
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    getSurpassTheIndex (lower, upper, value) {
      var params = [
        {
          text: 'L',
          class: 'text-primary'
        },
        {
          text: 'H',
          class: 'text-red'
        }
      ]
      if (value < lower) {
        return params[0]
      } else if (value > upper) {
        return params[1]
      }
    },
    roundToFive (num) {
      if (!num) return 0
      return +(Math.round(num + 'e+5') + 'e-5')
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.file-name {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

.file-name p {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>