<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <ResultTable :list_results="list_results" :documents="documents" @getResultsByDiseases="getResultsByDiseases"/>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
import ResultTable from '../../components/TestsGroup/ResultTable.vue'

export default {

  name: 'ResultDetail',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, ResultTable },
  data () {
    return {
      title: 'Kết luận',
      list_results: null,
      person_diseases_id: '',
      current_page: 1,
      index: null,
      documents: [],
      person_test_result: null,
      test_id: null
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.test_id = self.$route.params.test_id
    self.getTestResultDetail()
    self.getResultsByDiseases()
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/diseases/${this.$route?.params?.id}/test-info` })
    },
    async getTestResultDetail () {
      let self = this
      await self.$rf.getRequest('DoctorRequest').getTestResultDetail(self.test_id).then((resp) => {
        self.person_test_result = resp.data
        self.title = resp.data.test_result_name
        self.documents = resp.data.document
      })
    },
    async getResultsByDiseases () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 50,
          page: self.current_page,
          person_test_result_id: self.test_id || null
        }
        await self.$rf.getRequest('DoctorRequest').getResultsByDiseases(self.$route?.params?.id, params).then(res => {
          if (res && res.data) {
            self.list_results = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    isImage (file_name) {
      return (file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null)
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.result-table thead tr th {
    background-color: #E7EAF5 !important;
    border-bottom: none !important;
}
.file-name {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.file-name p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.table td, .table td * {
    vertical-align: top;
}
</style>