<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
        <div class="px-xl-5 px-3">
            <div class="px-xl-5">
                <div class="pt-3 border-bottom mb-3 border-dark" v-if="topic_detail && !loading_topic">
                    <div class="mb-3" v-if="topic_detail.type !== 6 && topic_detail.type !== 7">
                        <div :class="topic_detail && topic_detail.type == 8 ? 'd-flex justify-content-between' : ''">
                            <div>
                                <div class="d-flex w-100 py-3" :set="created = getUserCreated(topic_detail)">
                                    <div class="left-comment d-flex justify-content-center align-items-center" v-if="created">
                                        <div class="person-avatar" :set="has_avatar = hasAvatar(created.info)">
                                            <span v-if="has_avatar" class="avatar avatar-sender avatar-rounded" :style="'background-image: url('+getLinkDownload(created.info.user.avatar)+')'"></span>
                                            <span v-if="!has_avatar" class="avatar avatar-sender avatar-rounded text-white bg-dark">
                                                <span class="robo-14-500">{{ created.info && created.info.name ? created.info.name.charAt(0) : '' }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="right-comment pl-3">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <p class="mb-0 robo-20-500 txt-black" v-if="created"> {{created.info.name}}</p>
                                        </div>
                                        <div class="robo-16-400 txt-grey-900" v-if="topic_detail">{{topic_detail.updated_at ? 'Sửa lần cuối ' + formatHMNDMY(topic_detail.updated_at) : formatHMNDMY(topic_detail.created_at)}}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="topic_detail && topic_detail.type == 8 && checkTopic(topic_detail)" style="min-width: 40px;">
                                <div class="btn-group">
                                    <button class="border-0 bg-white" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img src="../../../public/assets/images/icon/3dot.svg">
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:;" @click="onShowModalTopicEdit(topic_detail, true)"><img src="../../../public/assets/images/icon/icon-edit.svg"><span class="robo-16-400 txt-pri ml-2">Chỉnh sửa</span></a>
                                        <div class="dropdown-divider d-none"></div>
                                        <a class="dropdown-item d-none" href="javascript:;"><img src="../../../public/assets/images/icon/rotate-left.svg"><span class="robo-16-400 txt-pri ml-2">Lịch sử</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="txt-black mb-2 p-0 bg-transparent border-0 robo-24-400">{{getTitleTopic()}}</p>
                            <p class="txt-black m-0 p-0 bg-transparent border-0 robo-18-400" v-if="topic_detail.content">{{topic_detail.content}}</p>
                            <TopicFileView  v-if="topic_detail.medias && topic_detail.medias.length" :medias="topic_detail.medias"></TopicFileView>
                        </div>
                    </div>
                    <div class="mb-2" v-if="topic_detail.type === 6 || topic_detail.type === 7">
                        <div v-if="topic_detail.attach && topic_detail.attach.method && topic_detail.attach.method == 2">
                            <div class="robo-24-500 txt-pri-corl d-flex justify-content-between">
                                <div>
                                    <img src="../../../public/assets/images/icon/icon-topic-video.svg" width="28" height="28"> Cuộc gọi video
                                </div>
                                <div>
                                    <div>{{appt && appt.invoice && appt.invoice.code ? '#'+appt.invoice.code : ''}}</div>
                                    <div class="robo-16-500 text-red">{{getStatusAppt(topic_detail.attach && topic_detail.attach.start_time)}}</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="topic_detail.attach && topic_detail.attach.method && topic_detail.attach.method == 1">
                            <div class="robo-24-500 txt-pri-corl"> <img src="../../../public/assets/images/icon/icon-topic-walk.svg" width="28" height="28">{{ $t("workspace_setting.lbl_appointment_to_workspace")[$orgModel] }}</div>
                        </div>
                        <div :class="topic_detail.attach && topic_detail.attach.method && topic_detail.attach.method == 2 ? 'd-flex justify-items-between' : ''">
                            <div>
                                <p class="mt-3" v-if="appt && appt.person && appt.person.name"><span class="robo-18-500">Đặt lịch hẹn với: </span><span class="robo-18-400">{{appt && appt.person && appt.person.name}}</span></p>
                                <p class="mt-3"><span class="robo-18-500">Thời gian: </span><span class="robo-18-400">{{topic_detail && topic_detail.attach && topic_detail.attach.start_time && formatHMNDMY(topic_detail.attach.start_time)}}</span></p>
                                <p v-if="topic_detail.attach && topic_detail.attach.method && topic_detail.attach.method == 2 && checkAppt(topic_detail)">
                                    <span class="robo-18-500">Đơn giá: </span>
                                    <span class="robo-18-400" :class="appt && appt.invoice && appt.invoice.price ? 'text--red' : 'text--green'">{{appt && appt.invoice && appt.invoice.price ? formatPrice(appt.invoice.price) : 'Hỗ trợ'}}</span>
                                    <span class="robo-18-400" v-if="appt && appt.invoice && appt.invoice.price" :class="appt && appt.invoice && appt.invoice.status ? getInvoiceStatus(appt.invoice.status).class : ''"> ({{appt && appt.invoice && appt.invoice.status ? getInvoiceStatus(appt.invoice.status).text : ''}})</span>
                                </p>
                                <div>
                                    <div class="d-flex" v-if="topic_detail && topic_detail.attach && topic_detail.attach.note">
                                        <span class="robo-18-500">Ghi chú: </span>
                                        <span class="robo-18-400 ml-1"> {{topic_detail && topic_detail.attach && topic_detail.attach.note}}</span>
                                    </div>
                                </div>
                                <p class="robo-18-500" v-if="(topic_detail.type === 6 || topic_detail.type === 7) && !!topic_detail.attach.consultation && !!topic_detail.attach.consultation.pdf_file">Kết quả khám</p>

                                  <div v-if="(topic_detail.type === 6 || topic_detail.type === 7) && !!topic_detail.attach.consultation && !!topic_detail.attach.consultation.pdf_file" class="my-2" @click="openPDF(topic_detail.attach.consultation.pdf_file)">
                                  <div class="cursor-pointer d-flex pdf-file align-items-center">
                                    <img src="../../../public/assets/images/icon/pdf-icon.svg" alt="" width="24" height="24" class="mr-2">
                                    <span class="robo-14-400">Phiếu tư vấn {{formatD(topic_detail.attach.start_time)}}</span>
                                  </div>
                                </div>   
                            </div>
                            <div class="ml-auto">
                                <button v-if="appt && appt.method == 2 && appt.invoice && appt.invoice.status != 3" class="btn btn--gray m-1 txt-pri" @click="goToInvoice()" :disabled="!checkMyAppt(appt)">
                                    Hóa đơn
                                </button>
                                <button v-if="appt && appt.method == 2" class="btn bg-pri bd-pri text-white m-1" @click="goToConsu(appt)" :disabled="!checkMyAppt(appt)">
                                    Phiếu tư vấn
                                </button>
                                <button v-if="appt && appt.method == 2" class="btn bg-pri bd-pri text-white m-1" :class="permitCall(appt) ? 'disabled' : ''" @click="videoCall(appt)" :disabled="!checkMyAppt(appt)">
                                    Tham gia ngay
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="loading_topic">
                    <div class="col-12">
                        <div class="mb-3">
                            <div class="card-body text-end">
                                <div class="skeleton-heading"></div>
                                <div class="skeleton-line"></div>
                                <div class="skeleton-line"></div>
                                <div class="skeleton-line"></div>
                                <div class="skeleton-line"></div>
                                <div class="skeleton-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="form-group">
                        <div class="bg-white border p-1 radius-10">
                            <textarea class="form-control robo-20-400 w-100 bg-white mb-2" :class="(cmt_images && cmt_images.length) || (cmt_videos && cmt_videos.length) || (cmt_files && cmt_files.length)  ? 'border-bottom bd-muted' : ''" rows="3" placeholder="Nhập nội dung" v-model="txt_content"></textarea>
                            <span class="robo-12-400 text--red" v-if="!txt_content && content_error">Vui lòng nhập nội dung!</span>
                            <TopicFilePreview :topic_images="cmt_images" :topic_videos="cmt_videos" :topic_files="cmt_files" @deleteMedia="deleteMedia"></TopicFilePreview>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-2 mb-2">
                        <TopicPicker @changeImage="changeImage" @changeVideo="changeVideo" @changeFile="changeFile"></TopicPicker>
                        <div>
                            <button :disabled="loading" class="btn bg-pri bd-pri text-white mb-1" style="width: 120px; height: 44px;" @click="onSubmit()">
                                <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
                                Gửi
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="!loading && comments && comments.count">
                    <div class="d-flex w-100 py-3 border-bottom" v-for="cmt in comments.data" :set="created = getUserCreated(cmt)" :key="cmt.id">
                        <div class="left-comment d-flex justify-content-center align-items-center" v-if="created">
                            <div class="person-avatar" :set="has_avatar = hasAvatar(created.info)">
                                <span v-if="has_avatar" class="avatar avatar-sender avatar-rounded" :style="'background-image: url('+getLinkDownload(created.info.user.avatar)+')'"></span>
                                <span v-if="!has_avatar" class="avatar avatar-sender avatar-rounded text-white bg-dark">
                                    <span class="robo-14-500">{{ created.info && created.info.name ? created.info.name.charAt(0) : '' }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="right-comment pl-3">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <p class="mb-0 robo-20-500 txt-black" v-if="created"> {{created.info.name}}</p>
                                <div class="mb-0 ml-auto">
                                    <div class="btn-group" v-if="checkCmt(cmt)">
                                        <button class="border-0 bg-white" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="../../../public/assets/images/icon/3dot.svg">
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="javascript:;" @click="onShowModalTopicEdit(cmt, false)"><img src="../../../public/assets/images/icon/icon-edit.svg"><span class="robo-16-400 txt-pri ml-2">Chỉnh sửa</span></a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:;" @click="onShowModalCommentsHistory(cmt)"><img src="../../../public/assets/images/icon/rotate-left.svg"><span class="robo-16-400 txt-pri ml-2">Lịch sử</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="txt-grey-900">
                                <pre class="m-0 p-0 bg-transparent border-0 robo-16-400">{{cmt.content || ''}}</pre>
                            </p>
                            <TopicFileView  v-if="cmt.medias && cmt.medias.length" :medias="cmt.medias"></TopicFileView>
                            <div class="d-flex justify-content-end" v-if="cmt.created_at">{{formatHMNDMY(cmt.created_at)}}</div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="loading">
                    <div class="col-12">
                        <div class="mb-3">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <div class="skeleton-avatar"></div>
                                        </div>
                                        <div class="col-7">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                        <div class="col-2 ms-auto text-end">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <div class="skeleton-avatar"></div>
                                        </div>
                                        <div class="col-7">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                        <div class="col-2 ms-auto text-end">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <div class="skeleton-avatar"></div>
                                        </div>
                                        <div class="col-7">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                        <div class="col-2 ms-auto text-end">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <div class="skeleton-avatar"></div>
                                        </div>
                                        <div class="col-7">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                        <div class="col-2 ms-auto text-end">
                                            <div class="skeleton-line"></div>
                                            <div class="skeleton-line"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Pagination v-if="comments && comments.count" :items="comments" :current_page="current_page" @onRefresh="getTopicComment"></Pagination>
            </div>
        </div>
        <ModalTopicEdit :item_edit="item_edit" @onRefresh="onRefresh" :is_topic="is_topic"></ModalTopicEdit>
        <ModalCommentsHistory :cmt="cmt"></ModalCommentsHistory>
        <CoolLightBox :items="getImagesForCoolightLocal(list_medias_local)" :index="index_media_local" :useZoomBar="true" @close="index_media_local = null"></CoolLightBox>
    </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
import ModalTopicEdit from '../../components/Topic/ModalTopicEdit.vue'
import ModalCommentsHistory from '../../components/Topic/ModalCommentsHistory.vue'
import TopicPicker from '../../components/FilePicker/TopicPicker.vue'
import TopicFilePreview from '../../components/FilePicker/TopicFilePreview.vue'
import TopicFileView from '../../components/FilePicker/TopicFileView.vue'
export default {

  name: 'TestDetail',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, ModalTopicEdit, ModalCommentsHistory, TopicPicker, TopicFilePreview, TopicFileView },
  data () {
    return {
      title: 'Trao đổi với bệnh nhân',
      person_diseases_id: '',
      current_page: 1,
      index: null,
      documents: [],
      topic_id: null,
      index_media_local: null,
      list_medias_local: null,
      index_media: null,
      list_medias: null,
      topic_detail: null,
      txt_content: '',
      cmt_images: null,
      cmt_videos: null,
      cmt_files: null,
      content_error: false,
      loading: false,
      loading_topic: false,
      person_id: null,
      comments: [],
      appt: null,
      doctor_id: null,
      care_team: null,
      item_edit: null,
      is_topic: false,
      cmt: ''
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getTopicDetail()
        this.getTopicComment()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    let user = appUtils.getLocalUser()
    self.person_diseases_id = self.$route?.params?.id
    self.topic_id = self.$route.params.topic_id
    self.getPersonsDiseases()
    self.getTopicDetail()
    self.getTopicComment()
    if (appUtils.isDoctor() && user) {
      self.doctor_id = user.doctor.id
    }
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/diseases/${this.$route?.params?.id}/activity` })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getPersonsDiseasesDetail(self.$route?.params?.id).then(res => {
          self.person_id = (res.data.person && res.data.person.id) || null
          self.care_team = (res.data.person && res.data.care_team) || null
          self.putPersonsCareplanSign(res?.data?.sign?.id)
        }).then(() => {})
      } catch (e) {
        console.log(e)
      }
    },
    async putPersonsCareplanSign (id) {
      let self = this
      if (!id) return
      try {
        self.$rf.getRequest('DoctorRequest').putPersonsCareplanSign(id, {diary_flag: 0}).then(res => {
        })
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async getTopicDetail () {
      let self = this
      self.loading_topic = true
      let params = {}
      await self.$rf.getRequest('DoctorRequest').getTopicDetail(self.$route.params.topic_id, params).then((resp) => {
        self.topic_detail = resp.data
        if (self.topic_detail.type === 6 || self.topic_detail.type === 7) {
          self.title = 'Lịch hẹn'
          if (self.topic_detail.attach_id && self.checkAppt(self.topic_detail)) {
            self.getAppts(self.topic_detail.attach_id)
          }
        } else if (self.topic_detail.type === 10 || self.topic_detail.type === 11) {
          self.title = 'Chi tiết dịch vụ'
        }
      }).catch((e) => {
        this.onClose()
      }).finally(() => {
        self.loading_topic = false
      })
    },
    async getAppts (id) {
      let self = this
      await self.$rf.getRequest('DoctorRequest').getAppts(id).then((resp) => {
        if (resp && resp.data) {
          self.appt = resp.data
        }
      }).catch((e) => {})
    },
    async getTopicComment (is_refresh) {
      let self = this
      self.loading = true
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        page: self.current_page,
        limit: 10,
        sort: 'created_at',
        sort_by: 'desc'
      }
      await self.$rf.getRequest('DoctorRequest').getTopicComments(self.$route.params.topic_id, params).then((resp) => {
        self.comments = resp.data
      }).finally(() => {
        self.loading = false
      })
    },
    getTitleTopic () {
      if (!this.topic_detail) return
      let topic = this.topic_detail
      let type = topic.type
      let attach = topic.attach
      if (type === 1 || type === 3) {
        return 'Đã tạo hồ sơ chăm sóc'
      }
      if (type === 2 || type === 5 || type === 9) {
        return 'Đã cập nhật thông tin sức khoẻ'
      }
      if (type === 6 || type === 7) {
        let str = attach.method === 2 ? 'Cuộc gọi Video Call ' : 'Hẹn đến cơ sở y tế '
        let date = this.formatHMNDMY(attach.start_time)
        return str + date
      }
      if (type === 8) {
        return topic.title
      }
      if (type === 10) {
        return 'Dịch vụ ' + attach.service_name
      }
      if (type === 11) {
        return 'HODO đã cập nhật trạng thái dịch vụ ' + attach.service_name
      }
      if (type === 12) {
        return 'Bác sĩ đã đồng ý tham gia theo dõi và tư vấn, có thể nhắn tin và trao đổi tại đây'
      }
    },
    onSubmit () {
      if (!this.txt_content) {
        this.content_error = true
        return
      }
      this.onPostComment()
    },
    async onPostComment () {
      let self = this
      try {
        self.loading = true
        let params = new FormData()
        params.append('content', self.txt_content)
        if (!!self.cmt_images && self.cmt_images.length) {
          self.cmt_images.forEach(item => {
            if (item) {
              if (item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null) {
                params.append('images[]', item)
              }
            }
          })
        }
        if (!!self.cmt_videos && self.cmt_videos.length) {
          self.cmt_videos.forEach(item => {
            if (item) {
              if (item.name.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) != null) {
                params.append('videos[]', item)
              }
            }
          })
        }
        if (!!self.cmt_files && self.cmt_files.length) {
          self.cmt_files.forEach(item => {
            if (item) {
              if (item.name.toLowerCase().match(/\.(pdf|docx|doc|xlsx|xls)$/) != null) {
                params.append('files[]', item)
              }
            }
          })
        }
        await self.$rf.getRequest('DoctorRequest').postTopicComment(self.$route.params.topic_id, params).then((resp) => {

        }).finally(() => {
          self.loading = false
          self.txt_content = ''
          self.cmt_images = []
          self.cmt_videos = []
          self.cmt_files = []
          self.getTopicComment()
        })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    getUserCreated (comment) {
      if (!comment) return
      if (comment.doctor) {
        return {
          info: comment.doctor
        }
      }
      if (comment.person) {
        return {
          info: comment.person
        }
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatHMNDMY (date_time) {
      let str = window.moment(date_time).format('HH:mm DD/MM/YY')
      return str.replace(' ', ' ngày ')
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    getBase64URL (file) {
      return URL.createObjectURL(file)
    },
    hasAvatar (person) {
      return !!(person && person.user && person.user.avatar)
    },
    onShowMedias (medias, index) {
      let self = this
      self.list_medias = medias
      setTimeout(() => {
        self.index_media = index
      }, 100)
    },
    onShowMediaLocal (medias, index) {
      let self = this
      self.list_medias_local = medias
      self.index_media_local = index
    },
    getImagesForCoolightLocal (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          let file_name = file.name.split('.')
          results.push({
            title: this.txt_content || 'File đã chọn',
            src: this.getBase64URL(file),
            type: file_name ? file_name.pop().toLowerCase() : ''
          })
        })
      }
      return results
    },
    changeImage (_files) {
      let self = this
      self.cmt_images = _files
    },
    changeVideo (_files) {
      let self = this
      self.cmt_videos = _files
    },
    changeFile (_files) {
      let self = this
      self.cmt_files = _files
    },
    formatPrice (num) {
      return num.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
    },
    getInvoiceStatus (stt) {
      let statuses = {}
      statuses[1] = { text: 'Chưa thanh toán', class: 'text--red' }
      statuses[2] = { text: 'Chờ xác nhận', class: 'text--yellow' }
      statuses[3] = { text: 'Đã thanh toán', class: 'text--green' }
      return stt && statuses[stt] ? statuses[stt] : statuses[1]
    },
    permitCall (appt) {
      let self = this
      // if (appt && (appt.status === 2 || appt.status === 5) && !self.isExpire(appt.start_time)) {
      if (appt && (appt.status === 2 || appt.status === 5)) {
        if (self.isPaid(appt)) {
          return true
        } else {
          return false
        }
      }
    },
    isPaid (appt) {
      if (appt) {
        if (appt.invoice) {
          return appt.invoice.status === 3
        } else {
          return false
        }
      }
    },
    goToCall () {
      let self = this
      if (self.appt && self.appt.room_call) {
        this.$router.push('/doctor/' + self.appt.id + '/meeting-room')
      }
    },
    goToInvoice () {
      let self = this
      if (self.appt && self.appt.invoice) {
        let invoice = self.appt.invoice
        self.$router.push({ path: '/doctor/invoice/' + invoice.id + '?token=' + invoice.token })
      }
    },
    checkMyAppt (appt) {
      let self = this
      if (appt) {
        return appt.doctor_id === self.doctor_id
      }
    },
    checkAppt (topic_detail) {
      let self = this
      if (topic_detail && topic_detail.attach && topic_detail.attach.doctor_id) {
        return topic_detail.attach.doctor_id === self.doctor_id
      } else {
        return false
      }
    },
    getStatusAppt (start_time) {
      var now = new Date()
      var str = ''
      if (start_time) {
        let time = new Date(start_time)
        let clinic = this.$globalClinic
        let minute = clinic && clinic.distance_time ? this.changeMinutes(clinic.distance_time) : 15
        time.setMinutes(time.getMinutes() + minute)
        if (time < now) {
          str = 'Đã kết thúc'
        }
      }
      return str
    },
    changeMinutes (time) {
      var arr = time.match(/^(\d{2}):(\d{2}):(\d{2})$/)
      return parseInt(arr[1]) * 60 + parseInt(arr[2])
    },
    videoCall (appt) {
      if (!appt) return
      this.$router.push({name: 'ApptCall', params: {id: appt.id}})
    },
    onShowModalTopic (show) {
      window.$('#modal__topic').modal(show ? 'show' : 'hide')
    },
    onShowModalTopicEdit (item, is_topic) {
      let self = this
      self.item_edit = item
      self.is_topic = is_topic
      self.onShowModalTopic(true)
    },
    onRefresh (is_topic) {
      let self = this
      if (is_topic) {
        self.getTopicDetail()
      } else {
        self.getTopicComment()
      }
      self.onShowModalTopic(false)
    },
    checkCmt (cmt) {
      let self = this
      if (cmt) {
        if (cmt.doctor_id) {
          return cmt.doctor_id === self.doctor_id
        } else {
          return false
        }
      }
    },
    checkTopic (topic) {
      let self = this
      if (topic) {
        if (topic.doctor_id) {
          return topic.doctor_id === self.doctor_id
        } else {
          return false
        }
      }
    },
    onShowModalComments (show) {
      window.$('#modal__comments-history').modal(show ? 'show' : 'hide')
    },
    onShowModalCommentsHistory (item) {
      let self = this
      self.cmt = item
      self.onShowModalComments(true)
    },
    formatD (date_time) {
      return window.moment(date_time).format('DD/MM/YY')
    },
    deleteMedia (idx, type) {
      let self = this
      if (type === 1) self.cmt_images = self.cmt_images.filter((ele, index) => index !== idx)
      if (type === 2) self.cmt_videos = self.cmt_videos.filter((ele, index) => index !== idx)
      if (type === 3) self.cmt_files = self.cmt_files.filter((ele, index) => index !== idx)
    },
    openPDF (path) {
      let url = appUtils.getDocumentURL(path)
      window.open(url, '_blank').focus()
      window.event.stopPropagation()
    },
    goToConsu (appt) {
      if (!appt) return
      if (appt.consultation) {
        this.$router.push({path: `/doctor/consultation/${appt.consultation.id}`})
      } else {
        this.$router.push({path: `/doctor/consultation-create?pdid=${appt.person_diseases_id}&aid=${appt.id}`})
      }
    }
  }
}
</script>
<style lang="css" scoped>

.left-comment {
    width: 48px;
    height: 48px;
}

.right-comment {
    width: calc(100% - 48px);
}

.avatar-sender {
    --tblr-avatar-size: 48px;
}

.not-focus:focus {
    background-color: transparent;
    border: none;
}
.pdf-file {
  background-color: #FFD6C3;
  border-radius: 6px;
  padding: 6px;
  width: 190px;
}
</style>