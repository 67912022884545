<template>
	<div class="container-window mt-3 mr-3 border bd-e4 px-3">
		<HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
		<div class="table-responsive">
			<div class="row mt-2 ml-1">
				<div class="col-sm-2 robo-14-400 txt-pri-corl">Tài liệu: Sinh hoá</div>
				<div class="col-sm-2 robo-14-400 txt-pri-corl">Ngày tải: 20/08/2021</div>
				<div class="col-sm-4 robo-14-400 txt-pri-corl">Người cập nhật: Nguyễn Văn A (Bác sĩ)</div>
			</div>
			<table class="table table-vcenter table-mobile-md card-table table-scroll mt-2">
				<thead>
					<tr>
						<th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #03267A;">Chỉ số</span></th>
						<th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #03267A;">Kết quả</span></th>
						<th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #03267A;">Vượt chỉ số</span></th>
						<th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #03267A;">Đơn vị</span></th>
						<th class="text-sm-center"><span class="ml-sm-2 robo-16-500" style="color: #03267A;">Bình thường</span></th>
					</tr>
				</thead>
				<tbody>
					<tr class="mt-2 mb-2 border-bottom">
						<td data-label="Ngày tháng" class="text-sm-center"><span class="robo-16-500 txt-black-gray ml-sm-2">BRC</span></td>
						<td data-label="Chẩn đoán" class="text-sm-center"><span class="robo-16-500 txt-black-gray">3.25</span></td>
						<td data-label="Đã điều trị" class="text-sm-center"><span class="robo-16-500 txt-black-gray">L</span></td>
						<td data-label="Diễn biến sức khỏe" class="text-sm-center"><span class="robo-16-500 txt-black-gray">T/L</span></td>
						<td data-label="Bệnh khác" class="text-sm-center"><span class="robo-16-500 txt-black-gray">4.3-5.8</span></td>
					</tr>
					<tr class="mt-2 mb-2 border-bottom">
						<td data-label="Ngày tháng" class="text-sm-center"><span class="robo-16-500 txt-black-gray ml-sm-2">HGB</span></td>
						<td data-label="Chẩn đoán" class="text-sm-center"><span class="robo-16-500 txt-black-gray">3.25</span></td>
						<td data-label="Đã điều trị" class="text-sm-center"><span class="robo-16-500 txt-black-gray">L</span></td>
						<td data-label="Diễn biến sức khỏe" class="text-sm-center"><span class="robo-16-500 txt-black-gray">T/L</span></td>
						<td data-label="Bệnh khác" class="text-sm-center"><span class="robo-16-500 txt-black-gray">4.3-5.8</span></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
export default {

  name: 'DocumentEchocardiography',
  components: { PersonProfile, HeaderWindow, TypeBar },
  data () {
    return {
      title: 'Xét nghiệm công thức máu',
      histories: null,
      person_diseases_id: ''
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/diseases/${this.$route?.params?.id}` })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>