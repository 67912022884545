<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        :person="person_disease && person_disease.person"
        :disease="person_disease"
        @showModalAppt="showModalAppt"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="person_disease" />
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="robo-24-500 txt-pri mb-2">
          {{ $t("obj_health_records.lbl_create_new_exchange") }}
        </div>
        <br />
        <div class="mb-4">
          <div class>
            <div class="form-group">
              <div class="bg-white p-1">
                <input
                  type="text"
                  class="form-control border-0 bg-white robo-20-400 mb-1"
                  :placeholder="$t('obj_health_records.lbl_title')"
                  v-model="txt_title"
                />
                <span
                  class="robo-12-400 text--red mb-2"
                  v-if="!txt_title && title_error"
                >
                  {{ $t("obj_health_records.lbl_please_enter_title") }}
                </span>
                <TopicFilePreview
                  class="mb-2"
                  :topic_images="topic_images"
                  :topic_videos="topic_videos"
                  :topic_files="topic_files"
                  @deleteMedia="deleteMedia"
                ></TopicFilePreview>
                <TopicPicker
                  @changeImage="changeImage"
                  @changeVideo="changeVideo"
                  @changeFile="changeFile"
                ></TopicPicker>
                <textarea
                  class="form-control robo-20-400 w-100 bg-white mb-2 border-top mt-2 radius-0"
                  rows="3"
                  :placeholder="$t('obj_health_records.lbl_enter_content')"
                  v-model="txt_content"
                ></textarea>
                <span
                  class="robo-12-400 text--red mb-2"
                  v-if="!txt_content && content_error"
                >
                  {{ $t("obj_health_records.lbl_please_enter_content") }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-2 mb-2">
              <div>
                <button
                  :disabled="loading"
                  class="btn bd-pri txt-pri mr-1 cancel-btn mb-1"
                  style="width: 120px; height: 44px"
                  @click="onClose()"
                >
                  {{ $t("obj_health_records.lbl_cancel") }}
                </button>
                <button
                  :disabled="loading"
                  class="btn bg-pri bd-pri text-white mb-1"
                  style="width: 120px; height: 44px"
                  @click="onSubmit()"
                >
                  <span
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    v-if="loading"
                  ></span>
                  {{ $t("obj_health_records.lbl_send") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAppointment
      @onRefresh="onRefresh()"
      :person_id="
        person_disease && person_disease.person_id
          ? person_disease.person_id
          : null
      "
      :request_id="
        person_disease && person_disease.request_id
          ? person_disease.request_id
          : null
      "
      :person_diseases_id="person_disease && person_disease.person_id"
      :root_screen="'Diseases Case'"
      :screen="'Diseases Case'"
    ></ModalAppointment>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import MedicineInfo from '../../components/Diseases/MedicineInfo'
import ModalAppointment from '../../components/Appointment/ModalAppointment'
import TopicFilePreview from '../../components/FilePicker/TopicFilePreview'
import TopicPicker from '../../components/FilePicker/TopicPicker.vue'
import i18n from '../../lang'
export default {
  name: 'ExchangeAdd',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    MedicineInfo,
    ModalAppointment,
    TopicFilePreview,
    TopicPicker
  },
  data () {
    return {
      loading: false,
      type: 1,
      show: 'diary',
      title: i18n.t('obj_health_records.lbl_diary'),
      person_id: null,
      person: null,
      danger_symptoms: [],
      disease: null,
      person_disease: null,
      monitor_stats: null,
      topic_images: [],
      topic_videos: [],
      topic_files: [],
      index_media_local: null,
      list_medias_local: null,
      txt_title: '',
      txt_content: '',
      title_error: false,
      content_error: false
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseasesDetail()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id + '/activity'
      })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.person_disease = res.data
              self.person_id = self.person_disease.person_id
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onSubmit () {
      if (!this.txt_title) {
        this.title_error = true
        return false
      }
      if (!this.txt_content) {
        this.content_error = true
        return false
      }
      if (this.loading) {
        return false
      }
      this.onCreateTopic()
    },
    async onCreateTopic () {
      let self = this
      try {
        self.loading = true
        let clinic_id = this.$clinicId
        let params = new FormData()
        params.append('content', self.txt_content)
        params.append('title', self.txt_title)
        params.append('receiver_clinic_id', clinic_id)
        self.topic_images.forEach((item) => {
          if (item) {
            if (
              item.name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
            ) {
              params.append('images[]', item)
            }
          }
        })
        self.topic_videos.forEach((item) => {
          if (item) {
            if (
              item.name.toLowerCase().match(/\.(mp4|ogx|oga|ogv|ogg|webm)$/) !=
              null
            ) {
              params.append('videos[]', item)
            }
          }
        })
        self.topic_files.forEach((item) => {
          if (item) {
            if (
              item.name.toLowerCase().match(/\.(pdf|docx|doc|xlsx|xls)$/) !=
              null
            ) {
              params.append('files[]', item)
            }
          }
        })
        await self.$rf
          .getRequest('DoctorRequest')
          .createTopic(self.$route?.params?.id, params)
          .then((resp) => {
            self.$router.push({
              path: `/doctor/diseases/${self.$route?.params?.id}/topic/${resp.data.id}`
            })
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    showModalAppt (is_show) {
      window.$('#modal__appt').modal(is_show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    getBase64URL (file) {
      return window.URL.createObjectURL(file)
    },
    changeImage (_files) {
      let self = this
      self.topic_images = _files
    },
    changeVideo (_files) {
      let self = this
      self.topic_videos = _files
    },
    changeFile (_files) {
      let self = this
      self.topic_files = _files
    },
    deleteMedia (idx, type) {
      let self = this
      if (type === 1) {
        self.topic_images = self.topic_images.filter(
          (ele, index) => index !== idx
        )
      }
      if (type === 2) {
        self.topic_videos = self.topic_videos.filter(
          (ele, index) => index !== idx
        )
      }
      if (type === 3) {
        self.topic_files = self.topic_files.filter(
          (ele, index) => index !== idx
        )
      }
    }
  }
}
</script>
<style lang="css" scoped>
.thread-medias-img {
  width: auto;
  height: 126px;
  min-width: 126px;
  border-radius: 12px;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-btn:hover {
  background-color: #fafafa;
  color: #20419b;
  border-color: #20419b !important;
}
</style>
