<template>
	<div class="container-window mt-3 mr-3 border bd-e4 px-3">
		<HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
		<div class="table-responsive">
			<table class="table table-vcenter table-mobile-md card-table table-scroll">
				<thead>
					<tr>
						<th class="col-sm-3 col-md-2"><span class="robo-16-500 txt-pri">Ngày tháng</span></th>
						<th class="col-sm-3 col-md-2"><span class="robo-16-500 txt-pri">Chẩn đoán</span></th>
						<th><span class="robo-16-500 txt-pri">Đã điều trị</span></th>
						<th><span class="robo-16-500 txt-pri">Diễn biến sức khỏe</span></th>
						<th><span class="robo-16-500 txt-pri">Bệnh khác</span></th>
					</tr>
				</thead>
				<tbody v-if="histories && histories.data" class="h-100">
					<tr class="mt-2 mb-2" v-for="(history, index) in histories.data" :key="index" :class="index !== 0 ? 'border-top' : ''">
						<td class="col-sm-3 col-md-2" data-label="Ngày tháng">
              <div class="robo-16-400 txt-black-gray">{{history && history.created_at ? formatDateDMY(history.created_at) : ''}}</div>
              <div class="robo-16-400 txt-pri" v-if="history.created_by_type === 1 || history.created_by_type === 4">{{history && history.created_by && history.created_by.name}}</div>
              <div v-if="history.created_by_type === 2" class="robo-16-400 txt-pri">{{history && history.person && history.person.name}}</div>
              <div v-if="history && history.created_by_type" class="robo-16-400 txt-pri">({{history.created_by_type === 1 ? 'Bác sĩ' : history.created_by_type === 2 ? 'Bệnh nhân' : history.created_by_type === 4 ? 'Người hỗ trợ' : ''}})</div>
              <div v-if="history && (!history.created_by_type || history.created_by_type == 3)" class="robo-16-400 txt-pri">HODO</div>
            </td>
						<td class="col-sm-3 col-md-2" data-label="Chẩn đoán"><span class="robo-16-400 txt-black-gray">{{history && history.diseases_name}}</span></td>
						<td data-label="Đã điều trị">
							<pre class="border-0 robo-16-400 txt-black-gray ff-robo p-0 bg-transparent">{{history && history.diseases_previous_cure}}</pre>
						</td>
						<td data-label="Diễn biến sức khỏe" class="robo-16-500">
							<pre class="border-0 robo-16-400 txt-black-gray ff-robo p-0 bg-transparent">{{history && history.diseases_current_cure}}</pre>
						</td>
						<td data-label="Bệnh khác">
							<pre class="border-0 robo-16-400 txt-black-gray ff-robo p-0 bg-transparent">{{history && history.background_diseases}}</pre>
						</td>
					</tr>
				</tbody>
			</table>
			<Pagination v-if="histories" :items="histories" :current_page="current_page" @onRefresh="personsDiseasesHistories"></Pagination>
		</div>
	</div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
export default {

  name: 'ProfileHistory',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination },
  data () {
    return {
      title: 'Lịch sử cập nhật hồ sơ sức khoẻ',
      histories: null,
      person_diseases_id: '',
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.personsDiseasesHistories()
  },
  methods: {
    onClose () {
      if (appUtils.isDoctor()) {
        this.$router.push({ path: `/doctor/diseases/${this.$route?.params?.id}/health-information` })
      } else if (appUtils.isAdmin()) {
        this.$router.push({ path: `/admin/diseases/${this.$route?.params?.id}/health-information` })
      }
    },
    async personsDiseasesHistories () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          page: self.current_page,
          sort: 'created_at',
          sort_by: 'desc'
        }
        if (appUtils.isDoctor()) {
          await self.$rf.getRequest('DoctorRequest').personsDiseasesHistories(self.$route?.params?.id, params).then(res => {
            if (res && res.data) {
              self.histories = res.data
            }
          })
        } else {
          await self.$rf.getRequest('AdminRequest').getPersonsDiseasesHistories(self.$route?.params?.id, params).then(res => {
            if (res && res.data) {
              self.histories = res.data
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
    min-width: 95px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid;
}

.vc-container {
    border: 0 !important;
}

a:hover {
    text-decoration: none;
}

.border-bottom-width-2 {
    border-bottom: solid 1.5px;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
.table td, .table td * {
    vertical-align: top;
}
</style>