<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person"
        :person="person"
        :disease="disease"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="disease" />
    <TestResultTable
      v-if="person && disease"
      :person="person"
      :disease="disease"
      :documents="documents"
      :list_tests="list_tests"
      @onRefresh="getPersonDiseaseTestResult"
    ></TestResultTable>
    <div
      v-if="documents && documents.length"
      style="overflow-x: auto; overflow-y: hidden"
    >
      <div class="w-100 d-flex mb-2">
        <div class="robo-20-400 txt-pri-corl ml-2">
          {{ $t("obj_health_records.lbl_attachments") }}
        </div>
      </div>
      <div class="w-100 d-flex mb-2">
        <!-- <div v-for="(dcm,idx) in documents" :key="idx" class="mr-2">
          <div v-if="isImage(dcm.name)">
            <img
              :src="getLinkDownload(dcm.link)"
              alt
              width="126"
              height="138"
              class="cursor-pointer bg-dark img-fit-cover"
              @click="index=idx"
            />
            <div class="text-center mt-1 file-name">
              <p class="txt-pri-corl mb-0">{{dcm.content}}</p>
              <p class="txt-pri-corl">{{fontmatDate(dcm.created_at)}}</p>
            </div>
          </div>
        </div>-->
        <a
          v-for="(dcm, idx) in documents"
          target="_blank"
          :href="getLinkDownload(dcm.link)"
          :download="dcm.name"
          class="mr-2"
          :key="idx"
        >
          <div v-if="!isImage(dcm.name)">
            <div class="cursor-pointer">
              <img
                src="../../../public/assets/images/icon/dowload-file.svg"
                alt
                width="138"
                height="138"
                class="cursor-pointer"
              />
            </div>
            <div class="text-center mt-1 file-name">
              <p class="txt-pri-corl mb-0">{{ dcm.content }}</p>
              <p class="txt-pri-corl">{{ fontmatDate(dcm.created_at) }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div>
      <CoolLightBox
        v-if="documents && documents.count"
        :items="getImagesFromEnries(documents.data)"
        :index="index"
        :useZoomBar="true"
        @close="index = null"
      ></CoolLightBox>
    </div>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import appUtils from '../../utils/appUtils'
import TestResultTable from '../../components/Diseases/TestResultTable'
export default {
  name: 'TestInformationManagement',
  components: { PersonProfile, HeaderWindow, TypeBar, TestResultTable },
  data () {
    return {
      type_date: null,
      dates: {
        start: new Date(),
        end: new Date()
      },
      show: 'test',
      title: this.$t('obj_health_records.lbl_health_information'),
      person_id: null,
      person: null,
      disease: null,
      list_tests: [],
      index: null,
      loading: false,
      current_page: 1,
      last_page: 1,
      documents: [],
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getPersonDiseaseTestResult()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: '/doctor/diseases/' + this.$route?.params?.id
      })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
            let person = res.data.person || null
            self.person_id = person ? person.id : null
            self.person = person || null
          })
          .then(() => {
            self.getPersonsDocument()
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getPersonDiseaseTestResult () {
      let self = this
      self.current_page = self.$route.query.page
      let params = {
        limit: 10,
        person_diseases_id: self.$route?.params?.id,
        page: self.current_page
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonDiseaseTestResult(params)
        .then((res) => {
          self.list_tests = res.data
          self.current_page = res.data.current_page
          self.last_page = res.data.last_page
        })
    },
    async getPersonsDocument (page = 1) {
      let self = this
      let params = {
        page_size: 100,
        person_diseases_id: self.disease && self.disease.id,
        page_num: page,
        order: 'desc',
        sort_by: 'created_at'
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonDiseaseDocuments(params)
        .then((res) => {
          self.documents = res.data.data
        })
    },
    getImagesFromEnries (entries) {
      let results = []
      entries.forEach((file) => {
        if (this.isImage(file.name)) {
          let file_name = file.name.split('.')
          results.push({
            title: file.content,
            src: appUtils.getDocumentURL(file.link),
            type: file_name ? file_name.pop().toLowerCase() : ''
          })
        }
      })
      return results
    },
    getLinkDownload (link) {
      return appUtils.getDocumentURL(link)
    },
    isImage (file_name) {
      return file_name.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    fontmatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.upload-btn {
  height: 138px;
  width: 126px;
  border: dashed 2px #20419b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-name {
  width: 138px;
  overflow: hidden;
  white-space: nowrap;
}

.file-name p {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
