<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person"
        :person="person"
        :monitor_stats="monitor_stats"
        :disease="disease"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="disease" />
    <div class="flex justify-end">
      <el-button type="primary" class="text-sm" @click="createPrescription()"
        >+ {{ $t("obj_health_records.lbl_add") }}</el-button
      >
    </div>
    <el-table
      class="w-full"
      :data="prescription.data"
      @row-click="viewDetail"
      row-class-name="cursor-pointer"
    >
      <el-table-column :label="$t('obj_health_records.lbl_prescription_name')">
        <template slot-scope="scope">
          <span class>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('obj_health_records.lbl_date_of_prescription')"
      >
        <template slot-scope="scope">
          <span class>{{ formatDateDMY(scope.row.date) }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('obj_health_records.lbl_doctor')">
        <template slot-scope="scope">
          <span class>{{ scope.row.prescribed_doctor }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('obj_health_records.lbl_health_facilities')">
        <template slot-scope="scope">
          <span class>{{ scope.row.prescribed_clinic }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('obj_health_records.lbl_created_at')">
        <template slot-scope="scope">
          <span class>{{ formatDateDMY(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="prescription && prescription.count"
      :items="prescription"
      :current_page="current_page"
      @onRefresh="getPrescriptions"
    ></Pagination>

    <ModalAddPrescription
      ref="ModalAddPrescription"
      @refresh="getPrescriptions"
    />
    <ModalViewPrescription ref="ModalViewPrescription" :disease="disease" />
  </div>
</template>
<script>
import PersonProfile from '../../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../../components/Diseases/TypeBar.vue'
import Pagination from '../../../components/Pagination'
import ModalAddPrescription from './ModalAdd.vue'
import ModalViewPrescription from './ModalView.vue'
import moment from 'moment'
import i18n from '../../../lang'
export default {
  name: 'DiseasesPrescriptionList',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    Pagination,
    ModalAddPrescription,
    ModalViewPrescription
  },
  data () {
    return {
      show: 'medicines',
      title: i18n.t('obj_health_records.lbl_medicine_info'),
      person_id: null,
      person: null,
      disease: null,
      monitor_stats: null,
      prescription: [],
      params: {
        page: 1,
        person_diseases_id: this.$route?.params?.id,
        limit: 10
      },
      total_item: 400,
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getStats()
    self.getPrescriptions()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}`
      })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
            let person = res.data.person || null
            self.person_id = person ? person.id : null
            self.person = person || null
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    async getPrescriptions () {
      try {
        this.current_page = parseInt(this.$route.query.page) || 1
        const param = {
          ...this.params,
          page: this.current_page
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .getPrescriptions(param)
          .then((res) => {
            if (res && res.data) {
              this.prescription = res.data
              this.current_page = res.data.current_page
              this.total_item = res.data.total
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    createPrescription (obj) {
      this.$refs.ModalAddPrescription.openDialog(obj)
    },
    viewDetail (obj) {
      this.$refs.ModalViewPrescription.openDialog(obj.id)
    },
    formatDateDMY (date) {
      return date && moment(date).format('DD/MM/YYYY')
    }
  }
}
</script>