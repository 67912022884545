<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="table-responsive">
      <table
        class="table table-vcenter table-mobile-md card-table table-scroll"
      >
        <thead>
          <tr>
            <th class="table-rp-w-120">
              <span class="ml-sm-2 robo-16-500" style="color: #3153b1">
                {{ $t("obj_health_records.lbl_time_v2") }}
              </span>
            </th>
            <th>
              <span class="ml-sm-2 robo-16-500" style="color: #3153b1">
                {{ $t("obj_health_records.lbl_medicine_name") }}
              </span>
            </th>
            <th class="text-sm-center table-rp-w-120">
              <span class="ml-sm-2 robo-16-500" style="color: #3153b1">
                {{ $t("obj_health_records.lbl_drinked_times") }}
              </span>
            </th>
            <th>
              <span class="ml-sm-2 robo-16-500" style="color: #3153b1">
                {{
                  $t("obj_health_records.lbl_feeling_after_taking_the_medicine")
                }}
              </span>
            </th>
            <th>
              <SearchInput
                @onSearch="onSearch"
                :search="search"
                class="border"
                :name="$t('obj_health_records.lbl_search_for_drug_names')"
                style="border-color: #c3cae7"
              ></SearchInput>
            </th>
          </tr>
        </thead>
        <tbody v-if="medicine_schedules && medicine_schedules.count">
          <tr
            class="my-2 py-3 bg-blue-gray"
            v-for="(schedule, index) in medicine_schedules.data"
            :key="index"
          >
            <td data-label="Thời gian" class="table-rp-w-120">
              <span class="robo-16-400 txt-black ml-sm-2">{{
                formatDateDMY(schedule.take_date)
              }}</span>
            </td>
            <td data-label="Tên thuốc">
              <span class="robo-16-400 txt-black">{{
                schedule.person_medicine && schedule.person_medicine.name
              }}</span>
            </td>
            <td
              data-label="Đã uống (lần)"
              class="text-sm-center table-rp-w-120"
            >
              <span
                class="robo-16-400 txt-black"
                v-if="schedule.person_medicine"
                >{{ schedule.times || 0 }}/{{
                  schedule.person_medicine.freq_per_day || 0
                }}</span
              >
            </td>
            <td data-label="Cảm giác sau khi dùng thuốc" colspan="2">
              {{ schedule.person_note }}
            </td>
          </tr>
        </tbody>
        <tbody
          v-if="(!medicine_schedules || !medicine_schedules.count) && !loading"
        >
          <tr>
            <td colspan="5">
              <div class="text-center pt-3 robo-16-500 txt-pri">
                {{ $t("obj_health_records.lbl_no_record_data") }}
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loading" style="height: 500px">
          <tr>
            <td colspan="5">
              <div class="text-center pt-3 robo-16-500 txt-pri">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        v-if="medicine_schedules && medicine_schedules.count"
        :items="medicine_schedules"
        :current_page="current_page"
        @onRefresh="getMedicineScheduleByDiseases"
      ></Pagination>
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Pagination from '../../components/Pagination.vue'
import SearchInput from '../../components/SearchInput'
export default {
  name: 'PersonMedicineSchedule',
  components: { PersonProfile, HeaderWindow, TypeBar, Pagination, SearchInput },
  data () {
    return {
      title: this.$t('obj_health_records.lbl_monitor_medication_compliance'),
      medicine_schedules: null,
      person_diseases_id: '',
      current_page: 1,
      person_test_result: null,
      medicine_id: null,
      loading: false,
      search: ''
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    // self.medicine_id = self.$route.params.medicine_id
    // self.start = self.$route.query.start
    // self.end = self.$route.query.start
    self.getMedicineScheduleByDiseases()
  },
  methods: {
    onClose () {
      if (appUtils.isDoctor()) {
        this.$router.push({
          path: `/doctor/diseases/${this.$route?.params?.id}`
        })
      } else if (appUtils.isAdmin()) {
        this.$router.push({
          path: `/admin/diseases/${this.$route?.params?.id}/detail`
        })
      }
    },
    onSearch (txt) {
      this.search = txt
      this.getMedicineScheduleByDiseases()
    },
    async getMedicineScheduleByDiseases () {
      let self = this
      self.loading = true
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 15,
          page: self.current_page,
          to: self.formatDateDMYHM(window.moment().endOf('day'))
        }
        if (self.medicine_id) {
          params.person_medicine_id = self.medicine_id
        }
        if (self.search) {
          params.search = self.search
        }
        if (appUtils.isDoctor()) {
          await self.$rf
            .getRequest('DoctorRequest')
            .getMedicineScheduleByDiseases(self.$route?.params?.id, params)
            .then((res) => {
              if (res && res.data) {
                self.medicine_schedules = res.data
              }
            })
        } else if (appUtils.isAdmin()) {
          await self.$rf
            .getRequest('AdminRequest')
            .getMedicineSchedules(self.$route?.params?.id, params)
            .then((res) => {
              if (res && res.data) {
                self.medicine_schedules = res.data
              }
            })
        }
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateDMYHM (date) {
      return window.moment(date).format('DD-MM-YYYY HH:mm')
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.file-name {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

.file-name p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-rp-w-120 {
  width: 120px;
}
</style>