<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow
      :title="$t('obj_health_records.lbl_health_information')"
      @onClose="onClose"
    ></HeaderWindow>
    <div class="mt-3 px-3">
      <InformationForm :disease="disease" :edit="true"></InformationForm>
    </div>
  </div>
</template>
<script>
import InformationForm from '../../components/Diseases/InfomationForm.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
export default {
  name: 'UpdateHealthInformation',
  components: { HeaderWindow, InformationForm },
  data () {
    return {
      disease: null
    }
  },
  mounted () {
    this.getPersonsDiseases()
  },
  methods: {
    onClose () {
      return this.$route?.params?.id
        ? this.$router
          .push({
            path: `/doctor/diseases/${this.$route?.params?.id}/health-information`
          })
          .catch((_) => {})
        : false
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
          })
          .then(() => {})
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>