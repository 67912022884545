<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person"
        :person="person"
        :disease="disease"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar :showing="show" :person_id="person_id" :disease="disease" />
    <TestTemplateTable
      :templates="templates"
      @selectTemplate="selectTemplate"
      @onShowCreateResult="onShowCreateResult"
    >
    </TestTemplateTable>
    <ModalCreateTests
      @refresh="restoreTests"
      :slt_temp="slt_temp"
      :tests="tests"
      @onSaveTest="onSaveTest"
      :loading="loading"
    ></ModalCreateTests>
    <ModalCreateResult
      @refresh="restoreTests"
      :slt_temp="slt_temp"
      :tests="tests"
      @onSaveResult="onSaveResult"
      :loading="loading"
    ></ModalCreateResult>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import TestTemplateTable from '../../components/Diseases/TestTemplateTable'
import ModalCreateTests from '../../components/Diseases/ModalCreateTests'
import ModalCreateResult from '../../components/Diseases/ModalCreateResult'
import i18n from '../../lang'
// import appUtils from '../../utils/appUtils'
export default {
  name: 'SelectTemplateTest',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    TestTemplateTable,
    ModalCreateTests,
    ModalCreateResult
  },
  data () {
    return {
      show: 'test',
      title: i18n.t('obj_health_records.lbl_health_information'),
      person_id: null,
      person: null,
      disease: null,
      index: null,
      loading: false,
      current_page: 1,
      last_page: 1,
      documents: [],
      templates: [],
      template_items: [],
      slt_temp: null,
      tests: [],
      monitor_stats: null
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
    self.getTestTemplates()
    self.getStats()
  },
  methods: {
    onClose () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/test-info`
      })
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
            let person = res.data.person || null
            self.person_id = person ? person.id : null
            self.person = person || null
          })
      } catch (e) {
        console.log(e)
      }
    },
    nextPage () {
      if (this.last_page <= this.current_page) {
        this.getTestTemplates(this.current_page + 1)
      }
    },
    async getTestTemplates (page = 1) {
      let self = this
      let params = {
        limit: 200,
        is_active: 1
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getTestTemplates(params)
        .then((res) => {
          self.templates = res.data
          self.current_page = res.data.current_page
          self.last_page = res.data.last_page
        })
    },
    async getTestTemplatesItems (id) {
      let self = this
      let params = {
        limit: 500,
        test_template_group_id: id
      }
      let resp = await self.$rf
        .getRequest('AuthRequest')
        .getTestTemplatesItems(params)
      if (resp && resp.data) {
        self.template_items = resp.data
        return resp.data
      }
    },
    async renderTest (template_items) {
      let self = this
      if (!self.slt_temp) return
      self.tests = []
      template_items.data.forEach((item, index) => {
        self.tests.push({
          test_name: item.test_name,
          test_value: '',
          test_unit: item.test_unit,
          lower_threshold: item.lower_threshold,
          upper_threshold: item.upper_threshold,
          person_test_result_id: self.slt_temp.id,
          person_diseases_id: self.$route?.params?.id
        })
      })
    },
    restoreTests () {
      let self = this
      if (!self.slt_temp || !self.template_items) return
      self.tests = []
      self.template_items.data.forEach((item, index) => {
        self.tests.push({
          test_name: item.test_name,
          test_value: '',
          test_unit: item.test_unit,
          lower_threshold: item.lower_threshold,
          upper_threshold: item.upper_threshold,
          person_test_result_id: self.slt_temp.id,
          person_diseases_id: self.$route?.params?.id
        })
      })
    },
    async selectTemplate (temp) {
      let self = this
      self.loading = true
      if (self.slt_temp && self.slt_temp.id === temp.id) {
        self.showModalTestCreate(true)
      } else {
        self.slt_temp = temp
        let resp = await self.getTestTemplatesItems(temp.id)
        console.log(resp)
        if (resp && resp.count) {
          await self
            .renderTest(resp)
            .then(() => {
              self.loading = false
              self.showModalTestCreate(true)
            })
            .catch((e) => {
              self.loading = false
            })
        } else {
          self.loading = false
          self.showModalTestCreate(true)
        }
      }
    },
    async onSaveTest (items, name) {
      let self = this
      self.loading = true
      let test_result = await self.createTestResult(name, 1)
      if (!test_result) {
        self.loading = false
        return
      }
      let params = {
        person_diseases_id: self.disease && self.disease.id,
        person_test_result_id: test_result.id,
        tests: items
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .multiCreatePersonTest(params)
        self.$toast.open({
          message: this.$t('obj_health_records.lbl_added_success'),
          type: 'success'
        })
      } catch (e) {
        self.$toast.open({
          message: this.$t('obj_health_records.lbl_added_fail'),
          type: 'warning'
        })
        console.log(e)
      } finally {
        self.loading = false
        self.onClose()
      }
    },
    async onSaveResult (items, name) {
      let self = this
      self.loading = true
      let test_result = await self.createTestResult(name, 2)
      if (!test_result) {
        self.loading = false
        return
      }
      let params = {
        person_diseases_id: self.disease && self.disease.id,
        person_test_result_id: test_result.id,
        results: items
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .multiCreatePersonResult(params)
        self.$toast.open({
          message: this.$t('obj_health_records.lbl_added_success'),
          type: 'success'
        })
      } catch (e) {
        self.$toast.open({
          message: this.$t('obj_health_records.lbl_added_fail'),
          type: 'warning'
        })
        console.log(e)
      } finally {
        self.loading = false
        self.onClose()
      }
    },

    async createTestResult (name, type) {
      let self = this
      let params = {
        test_result_name: name,
        person_id: self.person_id,
        person_diseases_id: self.disease && self.disease.id,
        test_result_desc: '',
        type: type
      }
      try {
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .createPersonDiseaseTestResult(params)
        if (!resp) return
        return resp.data
      } catch (e) {
        console.log(e)
        return false
      }
    },
    fontmatDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    showModalTestCreate (show) {
      window.$('#modal_test_create').modal(show ? 'show' : 'hide')
    },
    onShowCreateResult (show) {
      window.$('#modal_result_create').modal(show ? 'show' : 'hide')
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>