<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow
      :title="$t('obj_health_records.lbl_health_information')"
      @onClose="onClose"
      :type="'health-information'"
      :disease="disease"
    ></HeaderWindow>
    <div class="mt-3 px-3">
      <InformationForm
        :disease="disease"
        @onShowProfileHistory="onShowProfileHistory"
        :type="'health-information'"
      ></InformationForm>
      <ParameterMonitoring
        v-if="disease"
        @openSetting="onShowModalSetting"
        :disease="disease"
        :is_refresh="is_refresh"
      ></ParameterMonitoring>
    </div>
    <ModalParameterSetting
      v-if="disease && person && !is_refresh"
      :person_diseases_id="disease.id"
      :person_id="person.id"
      @onRefresh="onRefresh"
    ></ModalParameterSetting>
  </div>
</template>
<script>
import InformationForm from '../../components/Diseases/InfomationForm.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import ParameterMonitoring from '../../components/Diseases/ParameterMonitoring.vue'
import ModalParameterSetting from '../../components/Diseases/ModalParameterSetting.vue'
export default {
  name: 'HealthInformation',
  components: {
    HeaderWindow,
    InformationForm,
    ParameterMonitoring,
    ModalParameterSetting
  },
  data () {
    return {
      disease: null,
      person: null,
      is_refresh: false
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases()
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onClose () {
      return this.$route?.params?.id
        ? this.$router
          .push({ path: `/doctor/diseases/${this.$route?.params?.id}` })
          .catch(_ => {})
        : false
    },
    onShowModalSetting (show) {
      window.$('#modal-parameter-setting').modal(show ? 'show' : 'hide')
    },
    onRefresh () {
      this.is_refresh = true
      setTimeout(() => {
        this.is_refresh = false
      }, 100)
    },
    async getPersonsDiseases () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then(res => {
            self.disease = res.data
            self.person = res.data.person || null
            self.putPersonsCareplanSign(res?.data?.sign?.id)
          })
          .then(() => {})
      } catch (e) {
        console.log(e)
      }
    },
    async putPersonsCareplanSign (id) {
      let self = this
      if (!id) return
      try {
        self.$rf
          .getRequest('DoctorRequest')
          .putPersonsCareplanSign(id, { health_flag: 0 })
          .then(res => {})
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    onShowProfileHistory (show) {
      let self = this
      self.$router.push({
        path: `/doctor/diseases/${self.$route?.params?.id}/profile-history`
      })
    }
  }
}
</script>
<style lang="css" scoped>
</style>